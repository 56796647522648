<template>
  <div class="flex justify-center relative">
    <svg
      class="absolute"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_203_484)">
        <path
          d="M23.1865 15.8239C23.1865 11.4344 19.9257 8.29724 15.9115 8.29724C11.8973 8.29724 8.63647 11.433 8.63647 15.8239C8.63647 20.2147 11.939 23.4339 15.9115 23.4339C19.884 23.4339 23.1865 20.2564 23.1865 15.8239Z"
          fill="#6466f1"
        />

        <path
          d="M15.9993 0C7.16287 0 0 7.16287 0 16.0007C0 20.1975 1.61747 24.0176 4.26149 26.8715V5.38294H8.257V7.52806C10.2799 5.79845 12.9628 4.78627 15.9101 4.78627C22.2233 4.78627 27.3244 9.42733 27.3244 15.8239C27.3244 22.2204 22.2233 26.9463 15.9101 26.9463C12.9628 26.9463 10.2799 25.9255 8.257 24.1815V30.0044C10.5516 31.2754 13.1914 32 15.9993 32C24.8357 32 31.9986 24.8371 31.9986 16.0007C31.9986 7.16431 24.8357 0 15.9993 0Z"
          fill="#6466f1"
        />
      </g>

      <defs>
        <clipPath id="clip0_203_484">
          <rect
            width="32"
            height="32"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>

    <svg
      class="absolute motion-safe:animate-ping "
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_203_484)">
        <path
          d="M23.1865 15.8239C23.1865 11.4344 19.9257 8.29724 15.9115 8.29724C11.8973 8.29724 8.63647 11.433 8.63647 15.8239C8.63647 20.2147 11.939 23.4339 15.9115 23.4339C19.884 23.4339 23.1865 20.2564 23.1865 15.8239Z"
          fill="#6466f1"
        />

        <path
          d="M15.9993 0C7.16287 0 0 7.16287 0 16.0007C0 20.1975 1.61747 24.0176 4.26149 26.8715V5.38294H8.257V7.52806C10.2799 5.79845 12.9628 4.78627 15.9101 4.78627C22.2233 4.78627 27.3244 9.42733 27.3244 15.8239C27.3244 22.2204 22.2233 26.9463 15.9101 26.9463C12.9628 26.9463 10.2799 25.9255 8.257 24.1815V30.0044C10.5516 31.2754 13.1914 32 15.9993 32C24.8357 32 31.9986 24.8371 31.9986 16.0007C31.9986 7.16431 24.8357 0 15.9993 0Z"
          fill="#6466f1"
        />
      </g>

      <defs>
        <clipPath id="clip0_203_484">
          <rect
            width="32"
            height="32"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>

    <h4 class="mt-14 text-center text-xl text-indigo-950">
      <slot />
    </h4>
  </div>
</template>
